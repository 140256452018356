import React, { Component } from 'react';
import Menu from './menu'

class About extends Component {
  render() {
    return (
        <div>
          <h2>About</h2>
          <Menu />
        </div>
    );
  }
}

export default About;