import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
        <div>
          <h2>Error</h2>
        </div>
    );
  }
}

export default About;